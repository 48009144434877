import React, { Component, MouseEvent } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Tooltip,
  Row,
  Col,
  Divider,
  Checkbox,
  FormInstance,
} from 'antd';
import { AppleFilled } from '@ant-design/icons';
import {
  testPasswordStrength,
  getUrlQueryParams,
  // isFromTidy3d,
} from '@/utils/util';
import microsoftIcon from '../../../../assets/microsoft_icon.svg';
import { encodeUrlJson } from '../../../../utils/util';
import { CSSTransition } from 'react-transition-group';
// import { CSSTransition } from 'react-transition-group';

import styles from './index.module.less';
import PasswordStrength from '@/components/PasswordStrength';
import CountrySelect from '../../../../components/CountrySelect';
import { getCurrentAppConfig } from '@/constants/appConfig';
import googleIcon from '../../../../assets/google_icon.svg';
export interface Tidy3dSignUpPanelProps extends RouteComponentProps {
  onSubmit: (data: any) => void;
  loading: boolean;
}
export interface Tidy3dSignUpPanelState {
  subscribed: boolean;
  invitationCode: string;
  email: string;
  referralCode: string;
  ref: string;
  companyInvitationCode: string;
  agreementChecked: boolean;
  agreementAlert: boolean;
}

const { Item } = Form;
const { Password } = Input;

class Tidy3dSignUpPanel extends Component<
  Tidy3dSignUpPanelProps,
  Tidy3dSignUpPanelState
> {
  constructor(props: Tidy3dSignUpPanelProps) {
    super(props);
    const params = getUrlQueryParams();
    this.state = {
      subscribed: true,
      invitationCode: (params.invitation_code as string) || '',
      email: (params.email as string) || '',
      referralCode: (params.referralCode as string) || '',
      companyInvitationCode: (params.companyInvitationCode as string) || '',
      ref: (params.ref as string) || '',
      agreementChecked: false,
      agreementAlert: false,
    };
  }
  form: FormInstance | null = null;
  submit = (e: MouseEvent) => {
    this.form?.validateFields().then(formData => {
      const { agreementChecked } = this.state;
      if (!agreementChecked) {
        this.agreementAlert(true);
        return;
      }
      const { email, countryRegion, ...rest } = formData;
      const [country] = countryRegion;
      const { referralCode, invitationCode, companyInvitationCode } =
        this.state;
      const { onSubmit } = this.props;
      onSubmit({
        ...rest,
        email: email.toLowerCase(),
        country,
        referralCode,
        invitationCode,
        companyInvitationCode,
      });
    });
  };
  agreementAlert = (agreementAlert = false) => {
    this.setState({
      agreementAlert,
    });
  };

  render() {
    const { loading, location } = this.props;
    const currentAppConfig = getCurrentAppConfig();
    const layout = {
      labelCol: {
        span: 0,
      },
      wrapperCol: {
        span: 24,
      },
    };
    const {
      invitationCode,
      email,
      subscribed,
      referralCode,
      companyInvitationCode,
      ref,
      agreementAlert,
      agreementChecked,
    } = this.state;
    const isCompanyInvite = Boolean(companyInvitationCode);
    return (
      <div className={styles.tidy3d_sign_up_panel}>
        <div className={styles.title}>
          {isCompanyInvite ? 'Join Your Group' : 'Sign Up'}
        </div>
        <div className={styles.form}>
          <Form
            {...layout}
            ref={me => (this.form = me)}
            // onFinish={this.onFinish}
            initialValues={{ invitationCode, email, subscribed }}
            // id={'tidy3d-sign-up-form'}
          >
            <Row gutter={20}>
              <Col span={12}>
                <Item
                  name="firstName"
                  label="First Name"
                  validateFirst
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: 'Please input your first name',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={'First Name'}
                    maxLength={50}
                    size={'large'}
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  name="lastName"
                  label="Last Name"
                  validateFirst
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: 'Please input your last name',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={'Last Name'}
                    maxLength={50}
                    size={'large'}
                  />
                </Item>
              </Col>
            </Row>
            <Item
              name="email"
              label="Email"
              validateTrigger={'onBlur'}
              validateFirst
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'Please input your email',
                  whitespace: true,
                },
                {
                  type: 'email',
                  message: 'Invalid email address, please check',
                },
              ]}
            >
              <Input placeholder={'Email'} maxLength={50} size={'large'} />
            </Item>
            <Row gutter={20}>
              <Col span={24}>
                <Item
                  name="company"
                  label="Company"
                  validateFirst
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: 'Please input your company',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={'Company'}
                    maxLength={200}
                    size={'large'}
                  />
                </Item>
              </Col>
              {/*<Col span={12}>*/}
              {/*  <Item*/}
              {/*    name="companySize"*/}
              {/*    label="Company Size"*/}
              {/*    validateFirst*/}
              {/*    rules={[*/}
              {/*      {*/}
              {/*        type: 'string',*/}
              {/*        required: true,*/}
              {/*        message: 'Please select your company size',*/}
              {/*        whitespace: true,*/}
              {/*      },*/}
              {/*    ]}*/}
              {/*  >*/}
              {/*    <Select placeholder={'Company Size'}>*/}
              {/*      <Option value={'tiny'}>1-99 employees</Option>*/}
              {/*      <Option value={'small'}>100-249 employees</Option>*/}
              {/*      <Option value={'medium'}>250-499 employees</Option>*/}
              {/*      <Option value={'large'}>500+ employees</Option>*/}
              {/*    </Select>*/}
              {/*  </Item>*/}
              {/*</Col>*/}
            </Row>
            {/*{!isFromTidy3d() && (*/}
            {/*<Item*/}
            {/*  name="invitationCode"*/}
            {/*  label="Invitation Code"*/}
            {/*  rules={[*/}
            {/*    {*/}
            {/*      type: 'string',*/}
            {/*      required: true,*/}
            {/*      message: 'Please input your invitation code',*/}
            {/*      whitespace: true,*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*>*/}
            {/*  <Input placeholder={'Invitation Code'} maxLength={50} />*/}
            {/*</Item>*/}
            {/*)}*/}
            <Item
              name="countryRegion"
              // label="Country"
              validateFirst
              rules={[
                {
                  type: 'array',
                  required: true,
                  message: 'Please select your country',
                },
                // () => ({
                //   validator(_, value) {
                //     const [country, region] = value;
                //     if (country.code === 'US' && !region) {
                //       return Promise.reject(
                //         new Error('Please select your state/region')
                //       );
                //     }
                //     return Promise.resolve();
                //   },
                // }),
              ]}
            >
              <CountrySelect needRegion={false} />
            </Item>
            <Tooltip
              title="Password must have at least 8 characters, with at least 1 special
              character (!@#$%^&), 1 numeric, 1 lower and 1 upper case letter."
              placement="right"
            >
              <input
                autoComplete="off"
                type="text"
                style={{
                  position: 'fixed',
                  width: 0,
                  height: 0,
                  border: 'none',
                  padding: 0,
                  margin: 0,
                }}
              />
              <Item
                name="password"
                label="Password"
                validateFirst
                rules={[
                  { required: true, message: 'Please input your password' },
                  {
                    type: 'string',
                    min: 8,
                    message: 'Password must have at least 8 characters',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const email = getFieldValue('email');
                      if (
                        email?.length &&
                        value?.length &&
                        value.toLowerCase().includes(email.toLowerCase())
                      ) {
                        return Promise.reject(
                          new Error(
                            'Password should not contain current email address'
                          )
                        );
                      }
                      if (testPasswordStrength(value) < 4) {
                        return Promise.reject(
                          new Error(
                            'Please choose stronger password to protect your account'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Password
                  placeholder={'Password'}
                  maxLength={50}
                  size={'large'}
                />
              </Item>
            </Tooltip>
            <Item noStyle shouldUpdate={true}>
              {({ getFieldValue }) => {
                const passwordValue = getFieldValue('password') || '';
                const passwordStrength = testPasswordStrength(passwordValue);
                return (
                  passwordValue.length > 0 && (
                    <div className={styles.password_strength_wrapper}>
                      <label>Password Strength:</label>
                      <PasswordStrength level={passwordStrength} />
                    </div>
                  )
                );
              }}
            </Item>
            {/*<Item*/}
            {/*  name="confirmPassword"*/}
            {/*  label="Confirm Password"*/}
            {/*  validateFirst*/}
            {/*  rules={[*/}
            {/*    { required: true, message: 'Please confirm your password' },*/}
            {/*    ({ getFieldValue }) => ({*/}
            {/*      validator(_, value) {*/}
            {/*        if (!value || getFieldValue('password') === value) {*/}
            {/*          return Promise.resolve();*/}
            {/*        }*/}
            {/*        return Promise.reject(*/}
            {/*          new Error('Passwords do not match, please check')*/}
            {/*        );*/}
            {/*      },*/}
            {/*    }),*/}
            {/*  ]}*/}
            {/*>*/}
            {/*  <Password placeholder={'Confirm Password'} maxLength={50} />*/}
            {/*</Item>*/}
            <Item name={'subscribed'} valuePropName="checked">
              <Checkbox>Keep me updated about Tidy3D news</Checkbox>
            </Item>
            <CSSTransition
              classNames="agreement"
              in={agreementAlert}
              timeout={500}
              exit={false}
              onEntered={() => {
                this.agreementAlert(false);
              }}
            >
              <Item>
                <Checkbox
                  checked={agreementChecked}
                  onChange={e => {
                    this.setState({
                      agreementChecked: e.target.checked,
                    });
                  }}
                >
                  By checking this box and signing up, you agree to our{' '}
                  <a
                    href="//www.flexcompute.com/term_of_use/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <strong>terms of services</strong>
                  </a>{' '}
                  and{' '}
                  <a
                    href="//www.flexcompute.com/privacy/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <strong>privacy policy</strong>
                  </a>
                  .
                </Checkbox>
              </Item>
            </CSSTransition>
            <Item>
              <Button
                id="sign-up-with-email"
                type="primary"
                block
                onClick={e => this.submit(e)}
                loading={loading}
                size={'large'}
                className={'sign_up_btn'}
                // htmlType={'submit'}
                // disabled={!agreementChecked}
              >
                {isCompanyInvite ? 'Sign Up & Accept' : 'Sign Up'}
              </Button>
            </Item>
            {!isCompanyInvite && (
              <div className={styles.footer}>
                <div className={styles.text}>
                  Already have an account?&nbsp;
                  <Link
                    to={{ pathname: '/login', search: location.search }}
                    className={styles.link}
                  >
                    Sign in
                  </Link>
                </div>
                {/*<div className={styles.text}>*/}
                {/*  To use Flexcompute's Electromagnetic Product, please sign*/}
                {/*  up&nbsp;*/}
                {/*  <a*/}
                {/*    href="//tidy3d.simulation.cloud/register"*/}
                {/*    className={styles.link}*/}
                {/*  >*/}
                {/*    here*/}
                {/*  </a>*/}
                {/*</div>*/}
              </div>
            )}
            {currentAppConfig && (
              <>
                <Divider>OR</Divider>
                <Item>
                  <Button
                    id="sign-up-with-google"
                    block
                    onClick={() => {
                      if (!agreementChecked) {
                        this.agreementAlert(true);
                        return;
                      }
                      window.location.href = `${
                        currentAppConfig.googleLogin
                      }&state=${encodeUrlJson({
                        referralCode,
                        companyInvitationCode,
                        ref,
                      })}`;
                    }}
                    // disabled={!agreementChecked}
                    // className={'google_login'}
                    disabled={loading}
                    size={'large'}
                    icon={
                      <img
                        src={googleIcon}
                        width={14}
                        height={14}
                        className="anticon"
                        alt=""
                      />
                    }
                  >
                    Sign up with Google
                  </Button>
                </Item>
                {currentAppConfig.appleLogin && (
                  <Item>
                    <Button
                      id="sign-up-with-apple"
                      block
                      onClick={() => {
                        if (!agreementChecked) {
                          this.agreementAlert(true);
                          return;
                        }
                        window.location.href = `${
                          currentAppConfig.appleLogin
                        }&state=${encodeUrlJson({
                          referralCode,
                          companyInvitationCode,
                          ref,
                        })}`;
                      }}
                      disabled={loading}
                      size={'large'}
                      icon={<AppleFilled style={{ color: '#000' }} />}
                    >
                      Sign up with Apple
                    </Button>
                  </Item>
                )}
                {currentAppConfig.microsoftLogin && (
                  <Item>
                    <Button
                      id="sign-up-with-microsoft"
                      block
                      onClick={() => {
                        if (!agreementChecked) {
                          this.agreementAlert(true);
                          return;
                        }
                        this.props.history.push({
                          ...location,
                          pathname: '/microsoft_login',
                        });
                      }}
                      disabled={loading}
                      // className={'google_login'}
                      size={'large'}
                      icon={
                        <img
                          src={microsoftIcon}
                          width={14}
                          height={14}
                          className="anticon"
                          alt=""
                        />
                      }
                    >
                      Sign up with Microsoft
                    </Button>
                  </Item>
                )}
              </>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default withRouter(Tidy3dSignUpPanel);
