import { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import loadable from '@loadable/component';
import HomePage from './routes/Home';
import './App.less';
import 'animate.css';
import './styles/animation.less';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" component={HomePage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
