import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Divider,
  FormInstance,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { AppleFilled } from '@ant-design/icons';

import styles from './index.module.less';
import { getCurrentAppConfig } from '@/constants/appConfig';
import googleIcon from '../../../../assets/google_icon.svg';
import microsoftIcon from '../../../../assets/microsoft_icon.svg';
import {
  encodeUrlJson,
  getUrlQueryParams,
  isFromFlow360,
  isFromTidy3d,
  isFromInternal,
} from '../../../../utils/util';

const { Item } = Form;
const { Password } = Input;
const appTypeOptions = [
  { label: 'Flow360', value: 'FLOW360' },
  { label: 'Tidy3d', value: 'TIDY3D' },
];

export interface LoginPanelProps extends RouteComponentProps {
  onLogin: (data: any) => void;
  loading: boolean;
}
export interface LoginPanelState {
  referralCode: string;
  ref: string;
  appType: string;
}
class LoginPanel extends Component<LoginPanelProps, LoginPanelState> {
  constructor(props: LoginPanelProps) {
    super(props);
    const params = getUrlQueryParams();
    let appType = 'FLOW360'; // Default to FLOW360

    if (isFromFlow360()) {
      appType = 'FLOW360';
    } else if (isFromTidy3d()) {
      appType = 'TIDY3D';
    }
    this.state = {
      referralCode: (params.referralCode as string) || '',
      ref: params.ref as string,
      appType: appType,
    };
  }
  form: FormInstance | null = null;
  onChange = (e: RadioChangeEvent) => {
    this.setState({ appType: e.target.value });
  };
  login = () => {
    this.form?.validateFields().then(formData => {
      const { email } = formData;
      const { onLogin } = this.props;
      onLogin({
        ...formData,
        appType: this.state.appType.toUpperCase(),
        email: email.toLowerCase(),
      });
    });
  };
  render() {
    const { referralCode, ref, appType } = this.state;
    const { loading, location } = this.props;
    const currentAppConfig = getCurrentAppConfig();
    const layout = {
      labelCol: {
        span: 0,
      },
      wrapperCol: {
        span: 24,
      },
    };
    return (
      <div className={styles.login_panel}>
        <div className={styles.title}>Sign In</div>
        <div className={styles.form}>
          <Form
            {...layout}
            ref={me => (this.form = me)}
            // onFinish={this.onFinish}
          >
            {isFromInternal() && (
              <Item name="appType" label="Select App">
                <div>
                  <span>Account Type: </span>
                  <Radio.Group
                    defaultValue="FLOW360"
                    value={appType}
                    options={appTypeOptions}
                    onChange={this.onChange}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </div>
              </Item>
            )}
            <Item
              name="email"
              label="Email"
              validateTrigger={'onBlur'}
              rules={[
                {
                  required: true,
                  message: 'Please input your email',
                },
                {
                  type: 'email',
                  message: 'Invalid email address, please check',
                },
              ]}
            >
              <Input
                placeholder={'Enter email'}
                maxLength={50}
                onPressEnter={this.login}
                size={'large'}
              />
            </Item>
            <Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: 'Please input your password' },
              ]}
            >
              <Password
                placeholder={'Password'}
                maxLength={50}
                onPressEnter={this.login}
                size={'large'}
              />
            </Item>
            <Item>
              <Button
                id="sign-in-with-email"
                type="primary"
                block
                onClick={this.login}
                loading={loading}
                size={'large'}
              >
                Log In
              </Button>
            </Item>
            <div className={styles.footer}>
              <div className={styles.text}>
                Forgot password?&nbsp;
                <Link
                  to={{ pathname: '/reset_password', search: location.search }}
                  className={styles.link}
                >
                  Reset Password
                </Link>
              </div>
              <div className={styles.text}>
                Don’t have an account?&nbsp;
                <Link
                  to={{ pathname: '/signup', search: location.search }}
                  className={styles.link}
                >
                  Sign up
                </Link>
                {/*<a*/}
                {/*  href={'https://www.flexcompute.com/contact/'}*/}
                {/*  className={styles.link}*/}
                {/*>*/}
                {/*  Sign up*/}
                {/*</a>*/}
              </div>
            </div>
            {currentAppConfig && (
              <>
                <Divider>OR</Divider>
                <Item>
                  <Button
                    id="sign-in-with-google"
                    block
                    onClick={() => {
                      window.location.href = `${
                        currentAppConfig.googleLogin
                      }&state=${encodeUrlJson({
                        referralCode,
                        ref,
                      })}`;
                    }}
                    disabled={loading}
                    // className={'google_login'}
                    size={'large'}
                    icon={
                      <img
                        src={googleIcon}
                        width={14}
                        height={14}
                        className="anticon"
                        alt=""
                      />
                    }
                  >
                    Sign in with Google
                  </Button>
                </Item>
                {currentAppConfig.appleLogin && (
                  <Item>
                    <Button
                      id="sign-in-with-apple"
                      block
                      onClick={() => {
                        window.location.href = `${
                          currentAppConfig.appleLogin
                        }&state=${encodeUrlJson({
                          referralCode,
                          ref,
                        })}`;
                      }}
                      disabled={loading}
                      size={'large'}
                      icon={<AppleFilled style={{ color: '#000' }} />}
                    >
                      Sign in with Apple
                    </Button>
                  </Item>
                )}
                {currentAppConfig.microsoftLogin && (
                  <Item>
                    <Button
                      id="sign-in-with-microsoft"
                      block
                      onClick={() => {
                        this.props.history.push({
                          ...location,
                          pathname: '/microsoft_login',
                        });
                      }}
                      disabled={loading}
                      // className={'google_login'}
                      size={'large'}
                      icon={
                        <img
                          src={microsoftIcon}
                          width={14}
                          height={14}
                          className="anticon"
                          alt=""
                        />
                      }
                    >
                      Sign in with Microsoft
                    </Button>
                  </Item>
                )}
              </>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
export default withRouter(LoginPanel);
