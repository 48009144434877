import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Input, Button, Tooltip, Checkbox, FormInstance } from 'antd';
import {
  testPasswordStrength,
  getUrlQueryParams,
  // isFromTidy3d,
} from '@/utils/util';
import { CSSTransition } from 'react-transition-group';

import styles from './index.module.less';
import PasswordStrength from '@/components/PasswordStrength';
// import { getCurrentAppConfig } from '@/constants/appConfig';

interface SignUpPanelProps extends RouteComponentProps {
  onSubmit: (data: any) => void;
  loading: boolean;
}
interface SignUpPanelState {
  agreementChecked: boolean;
  invitationCode: string;
  email: string;
  agreementAlert: boolean;
}
const { Item } = Form;
const { Password } = Input;

class SignUpPanel extends Component<SignUpPanelProps, SignUpPanelState> {
  constructor(props: SignUpPanelProps) {
    super(props);
    const params = getUrlQueryParams();
    this.state = {
      agreementChecked: false,
      invitationCode: (params.invitation_code as string) || '',
      email: (params.email as string) || '',
      agreementAlert: false,
    };
  }
  form: FormInstance | null = null;
  submit = () => {
    this.form?.validateFields().then(formData => {
      const { agreementChecked } = this.state;
      if (!agreementChecked) {
        this.agreementAlert(true);
        return;
      }
      const { email } = formData;
      const { onSubmit } = this.props;
      onSubmit({
        ...formData,
        email: email.toLowerCase(),
      });
    });
  };
  agreementAlert = (agreementAlert = false) => {
    this.setState({
      agreementAlert,
    });
  };
  render() {
    const { loading, location } = this.props;
    // const currentAppConfig = getCurrentAppConfig();
    const layout = {
      labelCol: {
        span: 0,
      },
      wrapperCol: {
        span: 24,
      },
    };
    const { agreementChecked, invitationCode, email, agreementAlert } =
      this.state;
    return (
      <div className={styles.sign_up_panel}>
        <div className={styles.title}>Sign Up</div>
        <div className={styles.form}>
          <Form
            {...layout}
            ref={me => (this.form = me)}
            // onFinish={this.onFinish}
            initialValues={{ invitationCode, email }}
          >
            <Item
              name="email"
              label="Email"
              validateTrigger={'onBlur'}
              validateFirst
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'Please input your email',
                  whitespace: true,
                },
                {
                  type: 'email',
                  message: 'Invalid email address, please check',
                },
              ]}
            >
              <Input placeholder={'Email'} maxLength={50} size={'large'} />
            </Item>
            {/*{!isFromTidy3d() && (*/}
            <Item
              name="invitationCode"
              label="Invitation Code"
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'Please input your invitation code',
                  whitespace: true,
                },
              ]}
            >
              <Input
                placeholder={'Invitation Code'}
                maxLength={50}
                size={'large'}
              />
            </Item>
            {/*)}*/}
            <Tooltip
              title="Password must have at least 8 characters, with at least 1 special
              character (!@#$%^&), 1 numeric, 1 lower and 1 upper case letter."
              placement="right"
            >
              <Item
                name="password"
                label="Password"
                validateFirst
                rules={[
                  { required: true, message: 'Please input your password' },
                  {
                    type: 'string',
                    min: 8,
                    message: 'Password must have at least 8 characters',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const email = getFieldValue('email');
                      if (
                        email?.length &&
                        value?.length &&
                        value.toLowerCase().includes(email.toLowerCase())
                      ) {
                        return Promise.reject(
                          new Error(
                            'Password should not contain current email address'
                          )
                        );
                      }
                      if (testPasswordStrength(value) < 4) {
                        return Promise.reject(
                          new Error(
                            'Please choose stronger password to protect your account'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Password
                  placeholder={'Password'}
                  maxLength={50}
                  size={'large'}
                />
              </Item>
            </Tooltip>
            <Item noStyle shouldUpdate={true}>
              {({ getFieldValue }) => {
                const passwordValue = getFieldValue('password') || '';
                const passwordStrength = testPasswordStrength(passwordValue);
                return (
                  passwordValue.length > 0 && (
                    <div className={styles.password_strength_wrapper}>
                      <label>Password Strength:</label>
                      <PasswordStrength level={passwordStrength} />
                    </div>
                  )
                );
              }}
            </Item>
            <Item
              name="confirmPassword"
              label="Confirm Password"
              validateFirst
              rules={[
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Passwords do not match, please check')
                    );
                  },
                }),
              ]}
            >
              <Password
                placeholder={'Confirm Password'}
                maxLength={50}
                size={'large'}
              />
            </Item>
            <CSSTransition
              classNames="agreement"
              in={agreementAlert}
              timeout={500}
              exit={false}
              onEntered={() => {
                this.agreementAlert(false);
              }}
            >
              <Item>
                <Checkbox
                  checked={agreementChecked}
                  onChange={e => {
                    this.setState({
                      agreementChecked: e.target.checked,
                    });
                  }}
                >
                  By checking this box and signing up, you agree to our{' '}
                  <a
                    href="//www.flexcompute.com/term_of_use/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <strong>terms of services</strong>
                  </a>{' '}
                  and{' '}
                  <a
                    href="//www.flexcompute.com/privacy/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <strong>privacy policy</strong>
                  </a>
                  .
                </Checkbox>
              </Item>
            </CSSTransition>
            <Item>
              <Button
                id="sign-up-with-email"
                type="primary"
                block
                onClick={this.submit}
                loading={loading}
                size={'large'}
                className={'sign_up_btn'}
                // disabled={!agreementChecked}
              >
                Sign Up
              </Button>
            </Item>
            <div className={styles.footer}>
              <div className={styles.text}>
                Already have an account?&nbsp;
                <Link
                  to={{ pathname: '/login', search: location.search }}
                  className={styles.link}
                >
                  Sign in
                </Link>
              </div>
              {/*<div className={styles.text}>*/}
              {/*  To use Flexcompute's Electromagnetic Product, please sign*/}
              {/*  up&nbsp;*/}
              {/*  <a*/}
              {/*    href="//tidy3d.simulation.cloud/register"*/}
              {/*    className={styles.link}*/}
              {/*  >*/}
              {/*    here*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
            {/*{currentAppConfig && (*/}
            {/*  <>*/}
            {/*    <Divider>OR</Divider>*/}
            {/*    <Item>*/}
            {/*      <Button*/}
            {/*        block*/}
            {/*        onClick={() => {*/}
            {/*          if (!agreementChecked) {*/}
            {/*            this.agreementAlert(true);*/}
            {/*            return;*/}
            {/*          }*/}
            {/*          window.location.href = currentAppConfig.googleLogin;*/}
            {/*        }}*/}
            {/*        // disabled={!agreementChecked}*/}
            {/*        className={'google_login'}*/}
            {/*        loading={loading}*/}
            {/*      >*/}
            {/*        Continue with Google*/}
            {/*      </Button>*/}
            {/*    </Item>*/}
            {/*  </>*/}
            {/*)}*/}
          </Form>
        </div>
      </div>
    );
  }
}

export default withRouter(SignUpPanel);
