import axios, { AxiosResponse } from 'axios';
import { isFromTidy3d } from './util';
export interface ResponseData {
  code: number | string;
  [key: string]: any;
}
let fetcher = axios.create({
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'content-type': 'application/json',
    Application: isFromTidy3d() ? 'TIDY3D' : '',
  },
});

// Request interceptors
fetcher.interceptors.request.use(
  config => {
    const { headers = {}, params = {} } = config;
    const _ = Date.now();
    config.headers = {
      ...headers,
    };
    //add timestamp
    config.params = {
      _,
      ...params,
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response interceptors
function checkStatus(response: AxiosResponse<ResponseData>) {
  if (response.status >= 200 && response.status < 300) {
    response.data.code =
      parseInt(`${response.data.code}`, 10) || response.status;
    return response.data;
  }
}
fetcher.interceptors.response.use(
  function (response) {
    return checkStatus(response);
  },
  function (e) {
    return Promise.reject(e);
  }
);

fetcher.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const code = parseInt(error.response?.data?.code || error.response.status);
    const message = error.response?.data?.error || error.message;
    return Promise.reject({
      ...error,
      code,
      message,
    });
  }
);

// export const post = fetcher.post;
// export const put = fetcher.put;
// export const get = axios.get;
// export const del = (url, params) => {
//   return fetcher.delete(url, { params });
// };
export default fetcher;
