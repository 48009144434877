import React, { Component } from 'react';
import classnames from 'classnames';
import './index.less';

export interface PasswordStrengthProps {
  level: number;
}
export default class PasswordStrength extends Component<PasswordStrengthProps> {
  render() {
    const { level } = this.props;
    let tip = '';
    switch (level) {
      case 1:
      case 2:
        tip = 'weak';
        break;
      case 3:
        tip = 'medium';
        break;
      case 4:
        tip = 'strong';
        break;
      default:
    }
    const list = new Array(4).fill(1);
    return (
      <div className="password_strength">
        {list.map((item, index) => (
          <div
            key={index}
            className={classnames('strength_bar', {
              [tip]: level >= index + 1,
            })}
          />
        ))}
        <div className="strength_tip">{tip}</div>
      </div>
    );
  }
}
