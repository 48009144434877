import React, { Component } from 'react';
import { Row, Col, Select } from 'antd';
import countryList from './country-region.json';

const { Option } = Select;

export interface CountrySelectProps {
  value?: [Country | null, Region | null];
  needRegion: boolean;
  onChange?: (value: [Country | null, Region | null]) => void;
}
export interface CountrySelectState {
  selectedCountry: Country | null;
  selectedRegion: Region | null;
  regionList: Region[];
}
export interface Region {
  code: string;
  name: string;
}
export interface Country extends Region {
  regionList?: Region[];
}
export default class CountrySelect extends Component<
  CountrySelectProps,
  CountrySelectState
> {
  constructor(props: CountrySelectProps) {
    super(props);
    const { value } = props;
    const [selectedCountry = null, selectedRegion = null] = value || [];
    const regionList =
      countryList.find(country => country.code === selectedCountry?.code)
        ?.regionList || [];
    this.state = {
      selectedCountry,
      selectedRegion,
      regionList,
    };
  }
  isRegionSelectVisible = () => {
    const { needRegion } = this.props;
    if (!needRegion) {
      return false;
    }
    const { selectedCountry, regionList } = this.state;
    if (!selectedCountry || regionList?.length) {
      // if (regionList?.length) {
      return true;
    } else {
      return false;
    }
  };
  onCountryChange = (value: string, { data }: { data: Country }) => {
    const { onChange } = this.props;
    //exclude regionList from data
    const { regionList, ...selectedCountry } = data;
    this.setState(
      {
        selectedCountry: selectedCountry,
        selectedRegion: null,
        regionList: regionList || [],
      },
      () => {
        onChange?.([selectedCountry, null]);
      }
    );
  };
  onRegionChange = (value: string, { data }: { data: Region }) => {
    const { onChange } = this.props;
    const { selectedCountry } = this.state;
    this.setState(
      {
        selectedCountry,
        selectedRegion: data,
      },
      () => {
        onChange?.([selectedCountry, data]);
      }
    );
  };
  render() {
    const { selectedCountry, selectedRegion, regionList } = this.state;
    const isRegionVisible = this.isRegionSelectVisible();
    return (
      <Row gutter={20}>
        <Col span={isRegionVisible ? 12 : 24}>
          <Select
            placeholder={'Country / Region'}
            value={selectedCountry?.code}
            // @ts-ignore
            onChange={this.onCountryChange}
            showSearch
            optionFilterProp={'children'}
            size={'large'}
          >
            {countryList.map(country => (
              <Option key={country.code} value={country.code} data={country}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Col>
        {isRegionVisible && (
          <Col span={12}>
            <Select
              placeholder={'State/Region'}
              value={selectedRegion?.code}
              // @ts-ignore
              onChange={this.onRegionChange}
              showSearch
              optionFilterProp={'children'}
              size={'large'}
            >
              {regionList?.map(region => (
                <Option key={region.code} value={region.code} data={region}>
                  {region.name}
                </Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>
    );
  }
}
