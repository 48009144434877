import React, { Component } from 'react';
import { notification } from 'antd';
import LogRocket from 'logrocket';

import AuthLayout from '@/components/AuthLayout';
import { RouteComponentProps } from 'react-router-dom';
import ResetPasswordPanel from './components/ResetPasswordPanel';
import ResendModal from '@/components/ResendModal';
import api from '@/services';
import auth from '../auth';

import styles from './index.module.less';

export interface ResetPasswordPageProps extends RouteComponentProps {}
export interface ResetPasswordPageState {
  loading: boolean;
  successEmail: string;
  resendEmail: string;
  resendModalVisible: boolean;
}
class ResetPasswordPage extends Component<
  ResetPasswordPageProps,
  ResetPasswordPageState
> {
  constructor(props: ResetPasswordPageProps) {
    super(props);
    this.state = {
      loading: false,
      successEmail: '',
      resendEmail: '',
      resendModalVisible: false,
    };
  }

  componentDidMount() {}

  loading = (loading = false) => {
    this.setState({
      loading: loading,
    });
  };

  onReset = async (data: any) => {
    const { email } = data;
    try {
      this.loading(true);
      LogRocket.identify(email, {
        name: email,
        email,
      });
      LogRocket.track('reset password start', { email });
      await api.forgotPassword({
        email,
      });
      LogRocket.track('reset password success', { email });
      notification.success({
        message: 'Reset password request is sent',
        description: `Reset password email will be sent to ${email}, please check your inbox or contact the support`,
      });
      this.setState({
        successEmail: email,
      });
    } catch (e: any) {
      LogRocket.track('reset password error', { email, message: e.message });
      if (e.code === 4030000001) {
        this.showResendModal(email);
      } else {
        notification.success({
          message: 'Reset password request is sent',
          description: `Reset password email will be sent to ${email}, please check your inbox or contact the support`,
        });
        this.setState({
          successEmail: email,
        });
      }
    } finally {
      this.loading(false);
    }

    // resetPassword(email, {
    //   onSuccess: data => {
    //     _this.loading(false);
    //     notification.success({
    //       message: 'Reset password request is sent',
    //       description: `Reset password email will be sent to ${email}, please check your inbox or contact the support`,
    //     });
    //     LogRocket.track('reset password success', { email });
    //     _this.setState({
    //       successEmail: email,
    //     });
    //   },
    //   onError: e => {
    //     LogRocket.track('reset password error', { email, message: e.message });
    //     _this.loading(false);
    //     notification.success({
    //       message: 'Reset password request is sent',
    //       description: `Reset password email will be sent to ${email}, please check your inbox or contact the support`,
    //     });
    //     _this.setState({
    //       successEmail: email,
    //     });
    //   },
    // });
  };
  showResendModal = (resendEmail: string) => {
    this.setState({
      resendEmail,
      resendModalVisible: true,
    });
  };
  hideResendModal = () => {
    this.setState({
      resendModalVisible: false,
    });
  };
  render() {
    const { loading, successEmail, resendEmail, resendModalVisible } =
      this.state;
    return (
      <div className={styles.reset_password_page}>
        <AuthLayout>
          {successEmail ? (
            // <div className={styles.success_msg}>
            //   Reset password email has been sent to{' '}
            //   <strong>{successEmail}</strong>, please check your inbox.
            // </div>
            <div className={styles.success_msg}>
              Email sent, please check your inbox.
            </div>
          ) : (
            <ResetPasswordPanel onReset={this.onReset} loading={loading} />
          )}
        </AuthLayout>
        <ResendModal
          content={
            <div>
              Your account has not been activated yet. To activate, please
              verify your email address by clicking the button and following the
              instructions in the verification email.
            </div>
          }
          email={resendEmail}
          visible={resendModalVisible}
          onCancel={this.hideResendModal}
        />
      </div>
    );
  }
}
export default auth(ResetPasswordPage);
