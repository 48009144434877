import React, { Component } from 'react';
import classnames from 'classnames';
import './index.less';
import { isFromFlow360, isFromTidy3d } from '../../utils/util';
import logo from '../../assets/logo.svg';
import flow360Logo from '../../assets/flow360_logo.svg';
import tidy3dLogo from '../../assets/tidy3d_logo.svg';

export interface AuthLayoutProps {
  isFlow360?: boolean;
  isTidy3d?: boolean;
  showLogo?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}
export default class AuthLayout extends Component<AuthLayoutProps> {
  render() {
    const {
      isFlow360: isFlow360Props,
      isTidy3d: isTidy3dProps,
      showLogo = false,
      style,
    } = this.props;
    const isFlow360 = isFlow360Props || isFromFlow360();
    const isTidy3d = isTidy3dProps || isFromTidy3d();
    const isDefault = !isFlow360 && !isTidy3d;
    const { children } = this.props;
    return (
      <>
        <div
          className={classnames('auth_layout', {
            flow360: isFlow360,
            tidy3d: isTidy3d,
          })}
        >
          <div className="auth_content_wrapper">
            {showLogo && (
              <div className="logo_wrapper">
                {isFlow360 && (
                  <img src={flow360Logo} alt={'Flow360 logo'} width={204} />
                )}
                {isTidy3d && (
                  <img src={tidy3dLogo} alt={'Tidy3d logo'} width={204} />
                )}
                {isDefault && <img src={logo} alt={'Flexcompute logo'} />}
              </div>
            )}
            <div className="auth_content" style={style}>
              {children}
            </div>
          </div>
        </div>
      </>
    );
  }
}
