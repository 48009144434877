import LogRocket from 'logrocket';
import React, { PureComponent } from 'react';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isAndroid } from 'react-device-detect';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import loadable from '@loadable/component';
// import portalConfig from '../../constants/portalConfig';
import logo2 from '../../assets/logo2.png';
import './index.less';
import AndurilLoginPage from '../Anduril/Login';
import LoginPage from '../Login';
import ChallengePage from '../Challenge';
import MicrosoftLoginPage from '../MicrosoftLogin';
import ResetPasswordPage from '../ResetPassword';
import SignUpPage from '../SignUp';
import IpStatusContext from '../../IpStatusContext';

import api from '@/services';

import {
  getUser,
  initDocTitleAndDesc,
  isFromAnduril,
  isFromInternal,
} from '../../utils/util';

interface HomePageProps extends RouteComponentProps {}
export enum EIpStatus {
  HighRisk = 'HighRisk',
  Normal = 'Normal',
}
interface HomePageState {
  isLogRocketInited: boolean;
  ipStatus: EIpStatus | null;
}
const loadableConfig = {
  fallback: (
    <div className="loadable_loading_mask">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 45 }} spin />} />
    </div>
  ),
};
// const LoginPage = loadable(() => import('../Login'), loadableConfig);
// const ResetPasswordPage = loadable(
//   () => import('../ResetPassword'),
//   loadableConfig
// );
const ChangePasswordPage = loadable(
  () => import('../ChangePassword'),
  loadableConfig
);
// const SignUpPage = loadable(() => import('../SignUp'), loadableConfig);
// const SignUpWaitingPage = loadable(() => import('../SignUpWaiting'));
const VerifyEmailPage = loadable(
  () => import('../VerifyEmail'),
  loadableConfig
);
const VerifyMfaPage = loadable(() => import('../VerifyMfa'), loadableConfig);
const CallbackPage = loadable(() => import('../Callback'), loadableConfig);

export default class HomePage extends PureComponent<
  HomePageProps,
  HomePageState
> {
  // UNSAFE_componentWillMount() {
  //   this.unlisten = this.props.history.listen(() => {
  //     Modal.destroyAll();
  //   });
  // }
  state = {
    isLogRocketInited: false,
    ipStatus: null,
  };
  componentDidMount() {
    initDocTitleAndDesc();
    this.initLogRocket();
  }
  UNSAFE_componentWillReceiveProps(nextProps: HomePageProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      initDocTitleAndDesc();
      // this.initLogRocket();
    }
  }
  componentWillUnmount() {
    this.unlisten();
  }
  unlisten = this.props.history.listen(() => {
    Modal.destroyAll();
  });
  async initLogRocket() {
    const { isLogRocketInited } = this.state;
    let ipStatus: EIpStatus | null = this.state.ipStatus;
    if (isLogRocketInited) {
      return;
    }
    if (!ipStatus) {
      try {
        const { data } = await api.checkIp();
        ipStatus = data;
      } catch (e) {
        console.error(e);
        ipStatus = EIpStatus.Normal;
      }
    }
    this.setState(
      {
        ipStatus,
      },
      () => {
        const user = getUser();
        if (
          process.env.REACT_APP_LOGROCKET_ENABLED !== 'true' ||
          // check if android device
          isAndroid ||
          // check if ip illegal
          ipStatus !== EIpStatus.Normal ||
          // check if signup page
          // window.location.pathname.startsWith('/signup') ||
          // check if internal user
          user?.email?.endsWith('@flexcompute.com') ||
          user?.email?.endsWith('@magicloud.io') ||
          // check if from internal app
          isFromInternal()
        ) {
          // console.log('log session off');
        } else {
          LogRocket.init('eplwbm/simcloud-portal');
          LogRocket.getSessionURL(sessionURL => {
            LogRocket.log(`LogRocket session URL: ${sessionURL}`);
          });
          this.setState({
            isLogRocketInited: true,
          });
          // console.log('log session on');
        }
      }
    );
  }
  render() {
    const isAnduril = isFromAnduril();
    const { ipStatus } = this.state;
    // const isAnduril = true;
    return (
      <IpStatusContext.Provider value={ipStatus ?? ''}>
        <div className="home_page">
          {/*<Affix>*/}
          <div className="nav_bar">
            <a href="//www.flexcompute.com" className="logo_link">
              <img
                src={logo2}
                alt="Aerodynamics & Electromagnetic Simulation | Flexcompute"
                width={180}
              />
            </a>
            {/*<a href={portalConfig.homeUrl} className="product_link">*/}
            {/*  Select Product*/}
            {/*</a>*/}
          </div>
          {/*</Affix>*/}

          {isAnduril ? (
            <Switch>
              <Route path="/login" component={AndurilLoginPage} />
              <Route
                path="/callback/:app/:provider?"
                component={CallbackPage}
              />
              <Redirect
                to={{
                  ...this.props.location,
                  pathname: '/login',
                }}
              />
            </Switch>
          ) : (
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route path="/challenge" component={ChallengePage} />
              <Route path="/reset_password" component={ResetPasswordPage} />
              <Route
                path="/change_password/:email/:code"
                component={ChangePasswordPage}
              />
              <Route path="/signup" component={SignUpPage} />
              <Route path="/verify/:email/:code" component={VerifyEmailPage} />
              <Route path="/verify_mfa" component={VerifyMfaPage} />
              <Route
                path="/callback/:app/:provider?"
                component={CallbackPage}
              />
              <Route path="/microsoft_login" component={MicrosoftLoginPage} />
              <Redirect
                to={{
                  ...this.props.location,
                  pathname: '/login',
                }}
              />
            </Switch>
          )}
        </div>
      </IpStatusContext.Provider>
    );
  }
}
