import React from 'react';
import { Empty, EmptyProps } from 'antd';
import mailIcon from '@/assets/mail.svg';

export default function MailEmpty(props: EmptyProps) {
  return (
    <Empty
      image={<img src={mailIcon} width={60} height={60} alt="" />}
      {...props}
    />
  );
}
