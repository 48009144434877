import { isFromTidy3d } from '../utils/util';

export interface EnvConfig {
  s3: S3Config;
  webapiV2: {
    URL: string;
  };
  cognito: CognitoConfig;
  tidy3dCognito: CognitoConfig;
}
export interface S3Config {
  REGION: string;
  CASE_BUCKET: string;
  MESH_BUCKET: string;
  STUDIO_BUCKET: string;
}
export interface CognitoConfig {
  REGION: string;
  USER_POOL_ID: string;
  APP_CLIENT_ID: string;
  IDENTITY_POOL_ID: string;
}
const dev: EnvConfig = {
  s3: {
    REGION: 'us-east-1',
    CASE_BUCKET: 'flow360cases-v1',
    MESH_BUCKET: 'flow360meshes-v1',
    STUDIO_BUCKET: 'flow360-studio-v1',
  },
  webapiV2: {
    URL: 'https://portal-api.dev-simulation.cloud',
    //URL: "http://localhost:5000",
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_t41TfpFiq',
    APP_CLIENT_ID: '58h5c2luqdrads6cpjtbjmabjr',
    IDENTITY_POOL_ID: 'us-east-1:7d23a768-ca1c-446b-b91b-31d3e1fe968a',
  },
  tidy3dCognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_kjeChiCm8',
    APP_CLIENT_ID: '1imult4e80s1lfocqjpfv69rm2',
    IDENTITY_POOL_ID: 'us-east-1:6cdace1e-db96-4a52-b513-ceb212913de9',
  },
};

const prod: EnvConfig = {
  s3: {
    REGION: 'us-gov-west-1',
    CASE_BUCKET: 'flow360cases',
    MESH_BUCKET: 'flow360meshes',
    STUDIO_BUCKET: 'flow360studio',
  },
  webapiV2: {
    URL: 'https://portal-api.simulation.cloud',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_Csq1uNAO3',
    APP_CLIENT_ID: 'scepvluho5eeehv297pvdunk5',
    IDENTITY_POOL_ID: 'us-east-1:68a3cf31-60fc-4def-8db2-4c3d48070756',
  },
  tidy3dCognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_kfHmLjlPl',
    APP_CLIENT_ID: '45krrjc3bm1snlddbo79jfpk1d',
    IDENTITY_POOL_ID: 'us-east-1:dc691611-c253-4448-9b96-2bc507940155',
  },
};
const uat: EnvConfig = {
  ...prod,
  webapiV2: {
    URL: 'https://uat-portal-api.simulation.cloud',
  },
};
const uat2: EnvConfig = {
  s3: {
    REGION: 'us-west-2',
    CASE_BUCKET: 'flow360cases-uat2',
    MESH_BUCKET: 'flow360meshes-uat2',
    STUDIO_BUCKET: 'flow360-studio-uat2',
  },
  webapiV2: {
    URL: 'https://portal-api.uat-simulation.cloud',
    //URL: "http://localhost:5000",
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_Cb8xkdgub',
    APP_CLIENT_ID: '457ch71sk8k3cq5ki0o6k7ljhe',
    IDENTITY_POOL_ID: '',
  },
  tidy3dCognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_1zBiqSz9j',
    APP_CLIENT_ID: '6rt02ramf9ajkh93redglq4b4h',
    IDENTITY_POOL_ID: '',
  },
};
export function getConfig(): EnvConfig {
  const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
  if (BUILD_ENV === 'prod') {
    return prod;
  } else if (BUILD_ENV === 'uat') {
    return uat;
  } else if (BUILD_ENV === 'uat2') {
    return uat2;
  }
  return dev;
}

export default getConfig();

export function getCognitoConfig(): CognitoConfig {
  const config = getConfig();
  if (isFromTidy3d()) {
    return config.tidy3dCognito;
  }
  return config.cognito;
}
