import React, { Component } from 'react';

import AuthLayout from '@/components/AuthLayout';
import { RouteComponentProps } from 'react-router-dom';
import MicrosoftLoginPanel from './components/MicrosoftLoginPanel';
import api from '@/services';
import auth from '../auth';
import { getCurrentAppConfig } from '../../constants/appConfig';

import styles from './index.module.less';

export interface MicrosoftLoginPageProps extends RouteComponentProps {}
export interface MicrosoftLoginPageState {
  loading: boolean;
}
class MicrosoftLoginPage extends Component<
  MicrosoftLoginPageProps,
  MicrosoftLoginPageState
> {
  constructor(props: MicrosoftLoginPageProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {}

  loading = (loading = false) => {
    this.setState({
      loading: loading,
    });
  };

  onLogin = async (formData: { email: string }) => {
    try {
      this.loading(true);
      const { email } = formData;
      const { data } = await api.getMicrosoftLoginMetadata({
        email,
      });
      const appConfig = getCurrentAppConfig();
      const loginUrl = appConfig?.microsoftLogin;
      if (loginUrl) {
        const targetUrl = new URL(loginUrl);
        targetUrl.searchParams.set('identity_provider', data.provider);
        targetUrl.searchParams.set('client_id', data.clientId);
        window.location.replace(targetUrl.href);
      }
    } finally {
      this.loading(false);
    }
  };
  render() {
    const { loading } = this.state;
    return (
      <div className={styles.micro_soft_page}>
        <AuthLayout>
          <MicrosoftLoginPanel onLogin={this.onLogin} loading={loading} />
        </AuthLayout>
      </div>
    );
  }
}
export default auth(MicrosoftLoginPage);
