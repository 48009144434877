import { parseDomain, ParseResultType } from 'parse-domain';

export function getCookieDomain(hostname = window.location.hostname) {
  const parseResult = parseDomain(hostname);
  let cookieDomain;
  if (parseResult.type === ParseResultType.Listed) {
    const { domain, topLevelDomains } = parseResult;
    cookieDomain = `.${[domain, ...topLevelDomains].join('.')}`;
  }
  return cookieDomain;
}
const cookieDomain = getCookieDomain();
export { cookieDomain };
