import React, { PureComponent } from 'react';
import { isFromApp } from '../utils/util';
import portalConfig from '../constants/portalConfig';

// auth HOC, handle auth logic globally
export default function auth(WrappedComponent: Function) {
  return class extends PureComponent {
    isLegal = () => {
      const isSimulationCloud = isFromApp('');
      if (!isSimulationCloud) {
        this.goHome();
        return false;
      }
      return isSimulationCloud;
    };
    goHome = () => {
      window.location.href = portalConfig.homeUrl;
    };
    render() {
      if (!this.isLegal()) {
        return null;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}
