import React, { Component } from 'react';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { notification, Modal, Button, ModalProps } from 'antd';
import MailEmpty from '../MailEmpty';
import api from '@/services';
import './index.less';
export interface ResendModalProps extends ModalProps {
  email: string;
  content: React.ReactNode;
}
export interface ResendModalState {
  didResend: boolean;
  loading: boolean;
}
export default class ResendModal extends Component<
  ResendModalProps,
  ResendModalState
> {
  state = {
    didResend: false,
    loading: false,
  };
  loading = (loading = false) => {
    this.setState({
      loading,
    });
  };
  resend = async () => {
    try {
      this.loading(true);
      const { email } = this.props;
      await api.resendEmail({ email });
      this.setState({
        didResend: true,
      });
    } catch (e: any) {
      notification.error({
        message: 'Resend email error',
        description: e.message || 'Please try again or contact the admin',
      });
    } finally {
      this.loading(false);
    }
  };
  render() {
    const { content } = this.props;
    const { didResend, loading } = this.state;
    return (
      // @ts-ignore
      <Modal
        maskClosable={false}
        closable={false}
        footer={null}
        wrapClassName={'resend_email_modal'}
        centered
        {...this.props}
      >
        <div className="modal_content">
          <div className="content_title">
            {didResend ? (
              <CheckCircleOutlined style={{ color: '#52c41a' }} />
            ) : (
              <ExclamationCircleOutlined style={{ color: '#faad14' }} />
            )}
            {<span>{didResend ? 'Success' : 'Warning'}</span>}
          </div>
          <div className="info_content">
            {didResend ? (
              <MailEmpty
                description={
                  <div>
                    We sent a verification email to your account. Please follow
                    the instructions in the email to complete the signup
                    process.
                  </div>
                }
              />
            ) : (
              <div>{content}</div>
            )}
          </div>
          <div className="content_footer">
            {!didResend && (
              <Button onClick={this.resend} type={'primary'} loading={loading}>
                Resend verification email
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
