import { generator, RequestApiConfigMap } from '../utils/apiGenerator';
import config from '../constants/envConfig';
const { webapiV2 } = config;
const APIS: RequestApiConfigMap = {
  login: {
    method: 'get',
    url: `${webapiV2.URL}/auth`,
  },
  oauth2: {
    method: 'get',
    url: `${webapiV2.URL}/oauth2/token`,
  },
  sendRegisterEmail: {
    method: 'post',
    url: `${webapiV2.URL}/auth/register`,
  },
  sendWaitingEmail: {
    method: 'post',
    url: `${webapiV2.URL}/anonymous/waitinglist`,
  },
  verifyEmail: {
    method: 'put',
    url: `${webapiV2.URL}/auth/verify`,
  },
  resendEmail: {
    method: 'put',
    url: `${webapiV2.URL}/auth/resend`,
  },
  forgotPassword: {
    method: 'post',
    url: `${webapiV2.URL}/auth/forgot-password`,
  },
  saveKyc: {
    method: 'put',
    url: `${webapiV2.URL}/auth/kyc`,
  },
  mfaAuth: {
    method: 'post',
    url: `${webapiV2.URL}/auth/challenge`,
  },
  mfaReset: {
    method: 'post',
    url: `${webapiV2.URL}/auth/mfa/reset`,
  },
  mfaResetConfirm: {
    method: 'post',
    url: `${webapiV2.URL}/auth/mfa/resetConfirm`,
  },
  confirmPassword: {
    method: 'post',
    url: `${webapiV2.URL}/auth/confirm-forget-password`,
  },
  checkIp: {
    method: 'get',
    url: `${webapiV2.URL}/auth/check`,
    timeout: 3000,
  },
  getMicrosoftLoginMetadata: {
    method: 'get',
    url: `${webapiV2.URL}/auth/pre/metadata`,
    timeout: 3000,
  },
};
export default generator(APIS);
