import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Input, Button, FormInstance } from 'antd';

import styles from './index.module.less';

const { Item } = Form;

export interface MicrosoftLoginPanelProps extends RouteComponentProps {
  onLogin: (data: any) => void;
  loading: boolean;
}
class MicrosoftLoginPanel extends Component<MicrosoftLoginPanelProps> {
  form: FormInstance | null = null;
  login = () => {
    this.form?.validateFields().then((formData: any) => {
      const { email } = formData;
      const { onLogin } = this.props;
      onLogin({
        ...formData,
        email: email.toLowerCase(),
      });
    });
  };
  render() {
    const { loading, location } = this.props;
    // const layout = {
    //   labelCol: {
    //     span: 0,
    //   },
    //   wrapperCol: {
    //     span: 24,
    //   },
    // };
    return (
      <div className={styles.microsoft_login_panel}>
        <div className={styles.title}>Sign in with Microsoft</div>
        <div className={styles.form}>
          <Form
            layout={'vertical'}
            ref={me => (this.form = me)}
            // onFinish={this.onFinish}
          >
            <Item
              name="email"
              label="Fill in your email to login."
              validateTrigger={'onBlur'}
              rules={[
                {
                  required: true,
                  message: 'Please input your email',
                },
                {
                  type: 'email',
                  message: 'Invalid email address, please check',
                },
              ]}
            >
              <Input
                placeholder={'Enter email'}
                maxLength={50}
                size={'large'}
                onPressEnter={this.login}
              />
            </Item>
            <Item>
              <Button
                type="primary"
                block
                onClick={this.login}
                loading={loading}
                size={'large'}
              >
                Log In
              </Button>
            </Item>
          </Form>
          <div className={styles.footer}>
            <div className={styles.text}>
              Already have an account?&nbsp;
              <Link
                to={{ pathname: '/login', search: location.search }}
                className={styles.link}
              >
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(MicrosoftLoginPanel);
