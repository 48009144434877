import React, { Component } from 'react';
import { notification, Button } from 'antd';
import api from '@/services';

export interface ResendButtonProps {
  email: string;
}
export interface ResendButtonState {
  counter: number;
  loading: boolean;
}
export default class ResendButton extends Component<
  ResendButtonProps,
  ResendButtonState
> {
  state = {
    counter: 0,
    loading: false,
  };
  loading = (loading = false) => {
    this.setState({
      loading,
    });
  };
  resend = async () => {
    try {
      this.loading(true);
      const { email } = this.props;
      await api.resendEmail({ email });
      this.countDown(60);
      notification.success({
        message: 'Email sent, please check your inbox',
      });
    } catch (e: any) {
      notification.error({
        message: 'Resend email error',
        description: e.message || 'Please try again or contact the admin',
      });
    } finally {
      this.loading(false);
    }
  };
  countDown = (counter: number) => {
    this.setState({ counter }, () => {
      if (counter > 0) {
        setTimeout(() => {
          this.countDown(--counter);
        }, 1000);
      }
    });
  };
  render() {
    const { email } = this.props;
    const { counter, loading } = this.state;

    return (
      <Button
        onClick={this.resend}
        type={'link'}
        loading={loading}
        disabled={counter > 0}
        style={{ whiteSpace: 'normal' }}
      >
        {counter > 0 ? (
          <span>Verification email sent ({counter})</span>
        ) : (
          <span>Resend verification email to {email}</span>
        )}
      </Button>
    );
  }
}
