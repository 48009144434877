import {
  CognitoUserPool,
  // CognitoUserAttribute,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import { sha512 } from 'js-sha512';

import { getCognitoConfig } from '../constants/envConfig';
import { LOGIN_SALT } from '../constants';

const cognitoConfig = getCognitoConfig();
const poolData = {
  UserPoolId: cognitoConfig.USER_POOL_ID,
  ClientId: cognitoConfig.APP_CLIENT_ID,
};
const userPool = new CognitoUserPool(poolData);

// export function resetPassword(email, { onSuccess, onError, ...rest }) {
//   createUser(email).forgotPassword({
//     onSuccess,
//     onFailure: onError,
//     ...rest,
//   });
// }

// export function confirmPassword(
//   { email, password, code },
//   { onSuccess, onError, ...rest }
// ) {
//   createUser(email).confirmPassword(code, hashPassword(password), {
//     onSuccess,
//     onFailure: onError,
//     ...rest,
//   });
// }

// export function verifyEmail(email, code, callback) {
//   // @callback params
//   // (err,result)=>{}
//   createUser(email).confirmRegistration(code, true, callback);
// }

export function createUser(email: string) {
  return new CognitoUser({
    Username: toUsername(email),
    Pool: userPool,
  });
}

export function toUsername(email: string) {
  return email.toLowerCase().replace('@', '-at-');
}

export function hashPassword(password: string) {
  return sha512(password + LOGIN_SALT);
}
