import React, { Component } from 'react';
import { Button } from 'antd';
import AuthLayout from '@/components/AuthLayout';
import flow360Logo from '../../../assets/flow360_logo.svg';
import { jumpToAnduril } from '../../../utils/util';
import styles from './index.module.less';

export default class AndurilLoginPage extends Component {
  render() {
    return (
      <div className={styles.anduril_login_page}>
        <AuthLayout isFlow360={true} showLogo={false} style={{ width: 400 }}>
          <div className={styles.login_panel}>
            <img src={flow360Logo} alt={'Flow360 logo'} width={155} />
            <div className={styles.welcome}>Welcome to Flow360!</div>
            <div className={styles.intro}>
              Flow360 Solver is a Scalable CFD Solver for Better Product Design
            </div>
            <div>
              <Button
                onClick={() => {
                  jumpToAnduril();
                }}
                type={'primary'}
                className={styles.sign_in_btn}
              >
                Sign In
              </Button>
            </div>
          </div>
        </AuthLayout>
      </div>
    );
  }
}
