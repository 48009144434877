import React, { Component } from 'react';
import { notification, Modal, Button } from 'antd';
import LogRocket from 'logrocket';
import AuthLayout from '@/components/AuthLayout';
import { RouteComponentProps } from 'react-router-dom';
import SignUpPanel from './components/SignUpPanel';
import Tidy3dSignUpPanel from './components/Tidy3dSignUpPanel';
import api from '@/services';
import auth from '../auth';
import styles from './index.module.less';
import { toUsername, hashPassword } from '@/utils/cognitoHelper';
import {
  getAppNameFromUrl,
  // isDev,
  isFromTidy3d,
  // getUrlQueryParams,
  initHubspotForm,
} from '@/utils/util';
import MailEmpty from '../../components/MailEmpty';
import ResendButton from '../../components/ResendButton';
// const CONTACT_LINK = 'https://www.flexcompute.com/contact/';
export interface SignUpPageProps extends RouteComponentProps {}
export interface SignUpPageState {
  loading: boolean;
  successEmail: string;
}
class SignUpPage extends Component<SignUpPageProps, SignUpPageState> {
  constructor(props: SignUpPageProps) {
    super(props);
    this.state = {
      loading: false,
      successEmail: '',
    };
  }

  loading = (loading = false) => {
    this.setState({
      loading: loading,
    });
  };
  onSubmit = async (formData: any) => {
    // console.log('onSubmit', grecaptcha.getResponse());
    const { location } = this.props;
    const { email, invitationCode, password, ...rest } = formData;
    const isTidy3d = isFromTidy3d();
    let hsForm = null;
    try {
      this.loading(true);
      LogRocket.identify(email, {
        name: email,
        email,
      });
      LogRocket.track('sign up start', { email });
      const data = {
        ...rest,
        username: toUsername(email),
        password: hashPassword(password),
        rawPassword: password,
        invitationCode: invitationCode?.trim(),
        params: {
          email,
          // save the whole href to origin, then the querystring can be read in lambda
          'custom:origin': window.location.href,
          'custom:app': getAppNameFromUrl(),
        },
      };
      if (isTidy3d) {
        hsForm = initHubspotForm('tidy3d-sign-up-form', {
          email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          company: formData.company,
          country: formData.country?.name,
        });
      }
      await api.sendRegisterEmail(data);
      LogRocket.track('sign up success', { email });
      notification.success({
        message: 'Email sent',
        // message: 'Send signup verification email success',
        // description: `Signup verification email has been sent to ${email}, please check your inbox.`,
      });
      hsForm?.submit();
      this.setState({
        successEmail: email,
      });
    } catch (e: any) {
      LogRocket.track('sign up error', { email, message: e.message });
      if (e.code === 4000000004) {
        // existing user
        Modal.warning({
          title: 'Warning',
          width: 520,
          content: (
            <div>
              <div>
                This email has already been used. If you are the owner of the
                account, please sign in below.
              </div>
              <br />
              <div>
                <Button
                  type={'primary'}
                  size={'small'}
                  onClick={() => {
                    window.location.href = `/login${location.search}`;
                  }}
                >
                  Sign in
                </Button>
              </div>
            </div>
          ),
        });
      }
      // else if (e.code === 1000) {
      //   // kyc error
      //   Modal.warning({
      //     title: 'Warning',
      //     width: 520,
      //     content: (
      //       <>
      //         <div>Your account can not be created at this moment,</div>
      //         <div>
      //           please <a href={CONTACT_LINK}>contact us</a> for further
      //           assistance.
      //         </div>
      //       </>
      //     ),
      //   });
      // }
      else {
        notification.error({
          message: 'Sign up error',
          // message: 'Send signup verification email error',
          description: e.message || 'Please try again or contact the admin',
        });
      }
    } finally {
      this.loading(false);
    }
  };

  render() {
    const isTidy3d = isFromTidy3d();
    const { loading, successEmail } = this.state;
    return (
      <div className={styles.sign_up_page}>
        <AuthLayout>
          {successEmail ? (
            <MailEmpty
              description={
                <div className={styles.success_msg}>
                  <div>
                    We sent a verification email to your account. Please follow
                    the instructions in the email to complete the signup
                    process.
                  </div>
                  <div style={{ marginTop: 12 }}>
                    <ResendButton email={successEmail} />
                  </div>
                </div>
              }
            />
          ) : (
            <>
              {isTidy3d ? (
                <Tidy3dSignUpPanel onSubmit={this.onSubmit} loading={loading} />
              ) : (
                <SignUpPanel onSubmit={this.onSubmit} loading={loading} />
              )}
            </>
          )}
          {/*<div*/}
          {/*  className="g-recaptcha"*/}
          {/*  data-sitekey="6LfTmHspAAAAACL_tQiMDZuHx7z3D4JQpqpzclyQ"*/}
          {/*></div>*/}
        </AuthLayout>
      </div>
    );
  }
}
export default auth(SignUpPage);
