import { AxiosRequestConfig } from 'axios';
import request from './request';

export interface RequestApiConfig {
  method: 'get' | 'post' | 'put' | 'delete';
  url: string;
  isUpload?: boolean;
  timeout?: number;
}
export interface RequestApiConfigMap {
  [key: string]: RequestApiConfig;
}
const upload = (
  apiConfig: RequestApiConfig,
  data: any,
  config: AxiosRequestConfig
) => {
  const { url, method } = apiConfig;
  if (data instanceof FormData) {
  }
  return request({
    url,
    method,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
};

export const generator = (APIS: RequestApiConfigMap) => {
  const api: { [key: string]: Function } = {};
  Object.entries(APIS).forEach(([apiKey = '', apiConfig]) => {
    const { method = 'get', url = '', isUpload = false } = apiConfig || {};
    if (!apiKey || !apiConfig || !url) {
      return;
    }
    switch (method.toUpperCase()) {
      case 'POST':
        api[apiKey] = (data: any, config = {}) =>
          isUpload
            ? upload(apiConfig, data, config)
            : request.post(url, data, config);
        break;
      case 'DELETE':
        api[apiKey] = (params: any, config = {}) =>
          request.delete(url, { params, ...config });
        break;
      case 'PUT':
        api[apiKey] = (data: any, config = {}) =>
          request.put(url, data, config);
        break;
      case 'GET':
      default:
        api[apiKey] = (params: any, config = {}) =>
          request.get(url, { params, ...config });
    }
  });
  return api;
};
