import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Input, Button, FormInstance } from 'antd';
import OtpInput from 'react-otp-input';

import styles from './index.module.less';

const { Item } = Form;

export interface ChallengePanelProps extends RouteComponentProps {
  onSubmit: (data: any) => void;
  loading: boolean;
  onDisable: () => void;
}
class ChallengePanel extends Component<ChallengePanelProps> {
  form: FormInstance | null = null;
  submit = () => {
    this.form?.validateFields().then(async formData => {
      const { onSubmit } = this.props;
      try {
        await onSubmit(formData);
      } finally {
        this.reset();
      }
    });
  };
  reset = () => {
    this.form?.setFieldsValue({
      mfaCode: '',
    });
  };

  render() {
    const { loading, location, onDisable } = this.props;
    return (
      <div className={styles.challenge_panel}>
        <div className={styles.title}>Multi-Factor Authentication</div>
        <div className={styles.form}>
          <Form layout={'vertical'} ref={me => (this.form = me)}>
            <Item
              name="mfaCode"
              label="Enter an MFA code to complete sign in."
              validateTrigger={'onBlur'}
              rules={[
                {
                  required: true,
                  len: 6,
                  message: 'Please input 6 digits MFA code',
                },
              ]}
            >
              {/*<Input placeholder={'MFA Code'} maxLength={6} size={'large'} />*/}
              {/* @ts-ignore */}
              <OtpInput
                numInputs={6}
                // renderSeparator={<span></span>}
                containerStyle={styles.opt_input}
                inputStyle={styles.input_item}
                // @ts-ignore
                renderInput={props => <Input {...props} size={'large'} />}
              />
            </Item>
            <Item>
              <Button
                type="primary"
                block
                onClick={this.submit}
                loading={loading}
                size={'large'}
              >
                Submit
              </Button>
            </Item>
          </Form>
          <div className={styles.footer}>
            <div className={styles.text}>
              <Link
                to={{ pathname: '/login', search: location.search }}
                className={styles.link}
              >
                Cancel
              </Link>
            </div>
            <div className={styles.text}>
              Lost binding account?&nbsp;
              <span className={styles.link} onClick={onDisable}>
                Turn off MFA
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ChallengePanel);
